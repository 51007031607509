<template>
  <div class="home">
    <div class="container-fluid mt-0 px-0 max-width: 100%;">
        <img src="../assets/img/banners/59799webslide-allure-juli.jpg" class="img-fluid w-100 " alt="Responsive image">
    </div>
    <div class="container-fluid mt-5">
        <div class="container d-flex justify-content-center" >
            <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="embed-responsive embed-responsive-16by9">
                         <iframe class="embed-responsive-item"  width="100%" height="357px"  src="https://www.youtube.com/embed/WnEk75_3FrE" allowfullscreen></iframe>
                    </div>
                    <!-- <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" width="100%" height="400" src="https://www.youtube.com/embed/WnEk75_3FrE" alt="Responsive image" allowfullscreen></iframe>
                    </div> -->
                </div>
                <div class="col-md-12 col-12 col-lg-6 mt-lg-0 mt-4">
                    <h3 style="text-start">
                        Home Is Where The Heart Is
                    </h3>
                        <div class="col-2" style="background-color: #806858;">
                            <hr>
                        </div>
                    <p>
                        Allure Mansion adalah hunian di lingkungan asri kota Bekasi persembahan PT Reka Citra Propertindo yang dibangun dilahan seluas 5,3 hektar. Allure Mansion menawarkan kehidupan kota yang selaras dengan alam dan rasa nyaman yang bisa anda berikan untuk keluarga tercinta serta memberikan kemudahan untuk beraktivitas dengan adanya sarana transportasi yang lengkap dan akses tol yang dekat.
                    </p>
                    <p>
                    Desain arsitektur modern minimalis yang dimiliki Allure Mansion dilengkapi dengan penataan ruang yang opimal serta bangunan yang berkualitas akan membuat sebuah "rumah" menjadi sebuah tempat paling membanggakan yang tersimpan di hati Anda. Home, is where the heart is.
                    </p>

                </div>
            </div>
        </div>
    </div>
   <div class="container-fluid"  style="background-color: #806858;">
       <div class="container py-5 mt-4">
           <div class="row">
            <div class="col text-center mb-5">
                <h2><b>Benefit In Allure Mansion</b></h2>
                <strong>
                   <center> <hr style="border: 2px solid white; background-color: white ; color: white; width: 230px;"> </center>
                </strong>
            </div>
            <div class="row text-center d-flex justify-content-center">
                <div class="col-6 col-lg-2 col md-3 mt-3 ">
                    <img src="../assets/img/1.png" class="img-fluid" alt="...">
                    <div class="col">
                    <span>One Gate System & Security 24 hours</span>
                    </div>
                </div>
                <div class="col-6 col-lg-2 col md-3">
                    <img src="../assets/img/2.png" class="img-fluid" alt="...">
                    <div class="col">
                        <span>Toll Road Access JORR TB. Simatupang
                        </span>
                    </div>
                </div>
                <div class="col-6 col-lg-2 col md-3 mt-3">
                    <img src="../assets/img/3.png" class="img-fluid" alt="...">
                    <div class="col">
                        <span>Near From Halim Perdanakusuma Airport</span>
                    </div>
                </div>
                <div class="col-6 col-lg-2 col md-3 mt-3">
                    <img src="../assets/img/4.png" class="img-fluid" alt="...">
                    <div class="col">
                        <span>Shopping Center</span>
                    </div>
                </div>
                <div class="col-6 col-lg-2 col md-3 mt-3">
                    <img src="../assets/img/5.png" class="img-fluid" alt="...">
                    <div class="col">
                        <span>School & University</span>
                    </div>
                </div>
                <div class="col-6 col-lg-2 col md-3 mt-3">
                    <img src="../assets/img/6.png" class="img-fluid" alt="...">
                    <div class="col-12">
                        <span>Hospital</span>
                    </div>
                </div>
            </div>
           </div>

       </div>
   </div>
     
    <div class="container-fluid" style="background-color: #70625A;;">
        <div class="container py-5">
            <div class="row" >
                <div class="col-12 text-center mt-3 mt-lg-3">
                    <h2><b>LOCATION</b></h2>
                    <strong>
                       <center> <hr style="border: 2px solid white; background-color: white ; color: white; width: 180px;"> </center>
                    </strong>
                </div>
            </div>
            
        </div>
    </div>
   
    <div class="container-fluid px-0">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.4852086543106!2d106.93294131413866!3d-6.331124663708691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6992ddfd356411%3A0x29fb41b662ca1154!2sAllure%20Mansion!5e0!3m2!1sid!2sid!4v1632909662867!5m2!1sid!2sid" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div class="container-fluid" style="background-color: #806858;">
        <div class="container py-5 text-center">
            <div class="row-2">
                <div class="col-12 ">
                    <h2>UNIT TYPE</h2>
                    <strong>
                        <center><hr style="border: 2px solid white; background-color: white ; color: white; width: 180px;"> </center>
                    </strong>
                </div>
            </div>
            
        </div>
        <div class="container py-2" id="tx">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="card">
                        <img src="../assets/img/Allure Mansion - type ametis-01.png" class="card-img-top" alt="...">
                        <div class="card-body">
                            <div class="text-center">
                                <h3 class="card-title"><strong>Type Ametys</strong></h3>
                                <small>72/90 - 2 Lantai</small>
                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <table class="">
                                    <tr><td>Bedroom</td><td>: 3</td></tr>
                                    <tr><td>Bathroom</td><td>: 2</td></tr>
                                    <tr><td>Carport</td><td>: 1</td></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class=" col-12 col-lg-4 mt-3 mt-lg-0">
                   <div class="card">
                        <img src="../assets/img/Allure Mansion - type bacan-01.png" class="card-img-top" alt="...">
                        <div class="card-body">
                            <div class="text-center">
                                <h3 class="card-title"><strong>Type Bacan</strong></h3>
                             <small>78/105 - 2 Lantai</small>                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <table class="">
                                    <tr><td>Bedroom</td><td>: 3</td></tr>
                                    <tr><td>Bathroom</td><td>: 2</td></tr>
                                    <tr><td>Carport</td><td>: 1</td></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mt-3 mt-lg-0">
                   <div class="card">
                        <img src="../assets/img/Allure Mansion - type coral-01.png" class="card-img-top" alt="...">
                        <div class="card-body">
                            <div class="text-center">
                                <h3 class="card-title"><strong>Type Coral</strong></h3>
                             <small>90/120 - 2 Lantai</small>                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <table class="">
                                    <tr><td>Bedroom</td><td>: 3</td></tr>
                                    <tr><td>Bathroom</td><td>: 2</td></tr>
                                    <tr><td>Carport</td><td>: 1</td></tr>
                                </table>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12 col-lg-4">
                   <div class="card">
                        <img src="../assets/img/Allure Mansion - type edelwies-01.png" class="card-img-top" alt="...">
                        <div class="card-body">
                            <div class="text-center">
                                <h3 class="card-title"><strong>Type Edelwies</strong></h3>
                             <small>50/90 - 2 Lantai</small>                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <table class="">
                                    <tr><td>Bedroom</td><td>: 3</td></tr>
                                    <tr><td>Bathroom</td><td>: 2</td></tr>
                                    <tr><td>Carport</td><td>: 1</td></tr>
                                </table>
                            </div>
                        </div>
                      </div>
                </div>
                <div class="col-12 col-lg-4 mt-3 mt-lg-0">
                    <div class="card" id="tx">
                        <img src="../assets/img/Allure Mansion - type Jasmine-01.png" class="card-img-top" alt="...">
                        <div class="card-body">
                            <div class="text-center">
                                <h3 class="card-title"><strong>Type Jasmine</strong></h3>
                             <small>80/105 - 2 Lantai</small>                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <table class="">
                                    <tr><td>Bedroom</td><td>: 3</td></tr>
                                    <tr><td>Bathroom</td><td>: 2</td></tr>
                                    <tr><td>Carport</td><td>: 1</td></tr>
                                </table>
                            </div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="container-fluid mt-5" style="background-color: #70625A;" >
        <div class="container py-5 mb-3" >
                <div class="row-3 text-center">
                    <div class="col-12 mb-3">
                        <h2>Saya ingin mengetahui lebih lanjut mengenai Allure Mansion!</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center" >
                        <!-- <a id="contactButton" onclick="return gtag_report_conversion('https://wa.me/628111949478?text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website');" href="https://wa.me/628111949478?text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website" target="_blank" type="button" class="btn btn-success mt-3">
                            WhatsApp Sekarang
                        </a> -->
                        <a id="contactButton" target="_blank" @click="WhatsApp" type="button" class="btn btn-success mt-3 px-5 btn-lg">
                           <h4 class="m-0">WhatsApp Sekarang</h4> 
                        </a>
                        <!-- <button type="button" class="btn btn-success mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal2" >WhatsApp Sekarang</button> -->
                    </div>
                </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="container">
             <div class="row" >
                <div class="col-12 text-center mt-3 mt-lg-3">
                    <h3><b>CONTACT US </b></h3>
                    <strong>
                       <center> <hr style="border: 3px solid #70625A; background-color: #70625A ; color: #70625A; width: 180px;"> </center>
                    </strong>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid text-center text-md-start text-lg-start mt-5" >
        <div class="container ">
            <div class="row d-flex justify-content-between">
                    <div class="col-12 col-lg-4 col-md-12 align-center mb-5"> 
                        <div v-if="NamaSales.length>0" class="row d-flex justify-content-center">
                            <div class="col-6 col-md-6 col-lg-6 text-center justify-content-center image-sales">
                                <img :src="'https://api.makutapro.id/storage/photo/'+PhotoUser" alt="" class=" img-fluid" height="100%" width="auto">
                            </div> 
                            <div class="col-8 col-md-4 col-lg-6 text-center mt-4">
                                <span class="text-dark"><b>{{NamaSales}}</b>
                                <br><h6>Sales Representatif</h6></span>
                                
                                <a data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <img src="../assets/img/btn_wa2.png" width="170" >
                                </a>
                                
                            </div>   
                        </div>
                        
                        <div v-else class="row d-flex justify-content-center">
                            <div class="col-12 col-md-6 col-lg-12 text-center justify-content-center ">
                            <p><b>Chat for information details:</b></p>
                           
                                
                            <a id="contactButton"  target="_blank" @click="WhatsApp">
                                <img src="../assets/img/Screen Shot 2021-09-10 at 15.32 1.png" width="320">
                            </a>
                            </div>
                        </div>
                    </div>
               
                <div class="col-lg-4 col-12 col-md-12  mb-3 text-center">
                    <p>
                    <b>Follow Us</b>
                    </p>
                    <div class="justify-content-between mb-5">
                   <i class="fa fa-instagram me-3" aria-hidden="true" ></i>
                   <i class="fa fa-facebook ms-3" aria-hidden="true"></i>
                   </div>
                </div>
                <div class="col-12 col-lg-4 mb-5">
                    <p class="text-center text-lg-start mb-5">
                        <strong>ALLURE MANSION</strong>
                        <br>08111-9494-78
                        <br>info@alluremansion.co.id
                        <br>Marketing Galllery Allure Mansion
                        <br>Jl. Kampung Sawah Pabuaran No. 1
                        <br>Jatirangon - Bekasi
                    </p>
                    <p class="text-center text-lg-start mb-0"><b>Depeloved By</b></p>
                    <img src="../assets/img/logo.png" class="img-fluid align-self-center align-self-lg-start" alt="logo reka cipta propertindo">
                </div>
            </div>
        </div> 
    </div>
    <div class="p-3 mb-0 " style="background-color: #70625A;">
        <div class="row" >
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center" id="exampleModalLabel">Hubungi saya dan kirimkan brosur beserta daftar harga:</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <input type="text" class="form-control" v-model="NamaProspect" id="recipient-name" placeholder="Nama">
                        </div>
                        <div class="mb-3">
                            <input type="text" class="form-control" id="message-text" v-model="Hp" placeholder="Phone">
                            <small v-if="infoError" style="color:red;">{{infoError}}</small>
                        </div>
                        <div class="mb-3">
                            <input type="email" class="form-control" id="recipient-name" v-model="EmailProspect" placeholder="Email">
                        </div>
                        <!-- <div class="mb-3">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Pilih Sumber Informasi</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div> -->
                    </form>
                </div>
                <div class="d-grid gap-2 mb-3 mx-3 text-center">
                    <button class="btn btn-success " type="submit" @click="HandleSubmit()">WhatsApp Sekarang</button>
                </div>                        
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center" id="exampleModalLabel">Hubungi saya dan kirimkan brosur beserta daftar harga:</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <input type="text" class="form-control" v-model="NamaProspect" id="recipient-name" placeholder="Nama">
                        </div>
                        <div class="mb-3">
                            <input type="text" class="form-control" id="message-text" v-model="Hp" placeholder="Phone">
                            <small v-if="infoError" style="color:red;">{{infoError}}</small>
                        </div>
                        <div class="mb-3">
                            <input type="email" class="form-control" id="recipient-name" v-model="EmailProspect" placeholder="Email">
                        </div>
                        <!-- <div class="mb-3">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Pilih Sumber Informasi</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div> -->
                    </form>
                </div>
                <div class="d-grid gap-2 mb-3 mx-3 text-center">
                    <button class="btn btn-success " type="submit" @click="HandleSubmit">WhatsApp Sekarang</button>
                </div>                        
            </div>
        </div>
    </div>
  </div>

</template>

<script>
let urlWeb = document.URL;
let url = new URL(urlWeb);
let utm_source = url.searchParams.get('utm_source')
let utm_campaign = url.searchParams.get('utm_campaign')
let utm_medium = url.searchParams.get('utm_medium')
export default {
  name: 'App',

  props: ['UsernameKP', 'WA'],
  data(){
        return{
            
            NamaSales : '',
            KodeSales : '',
            HpSales: '',
            PhotoUser : '',
            NamaProspect : '',
            EmailProspect : '',
            Hp : '',
            infoError : '',
            //  utm_source : ''
            
            
        }
  },
  created(){
      this.utm_source = utm_source;
      this.utm_medium = utm_medium;
      this.utm_campaign = utm_campaign;
      let config = {
          headers : {
            'Access-Control-Allow-Origin' : '*',
            'Content-type' : 'application/json',
            'Access-Control-Allow-Methods' : 'GET, POST',
            'Access-Control-Allow-Headers' : 'X-Requested-With',
            'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
          },
        }
      console.log(this.UsernameKP)
      this.axios.get("https://api.makutapro.id/sales?UsernameKP="+this.UsernameKP+'&KodeProject=AM', config)
        .then(res => (
            console.log(res),
            this.NamaSales = res.data.data[0].NamaSales,
            this.KodeSales = res.data.data[0].KodeSales,
            this.HpSales = res.data.data[0].Hp,
            this.PhotoUser = res.data.data[0].PhotoUser
            
        ))
        .catch(err => console.log(err))

    //   console.log(this.WA)
    //   this.axios.get("https://wa.me/628111949478?text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website%20melalui%20?WA="+this.WA, config)
    //     .then(res => (
    //         console.log(res),
    //         this.utm_source = res.data.data[0].utm_source
            
    //     ))
    //     .catch(err => console.log(err))

    },
    methods: {
        HandleSubmit(){
             let config = {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    'Content-type' : 'application/json',
                    'Access-Control-Allow-Methods' : 'GET, POST',
                    'Access-Control-Allow-Headers' : 'X-Requested-With',
                    'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
                },
            }
            let formData = new FormData()
            formData.append('NamaProspect', this.NamaProspect)
            formData.append('KodeSales', this.KodeSales)
            formData.append('Hp', this.Hp)
            formData.append('EmailProspect', this.EmailProspect)
            formData.append('KodePT', 'RKC')
            formData.append('KodeProject', 'AM')


            this.axios.post('https://api.makutapro.id/prospect/sales', formData, config)
            .then(response=>{
               if(response.data.message == "Nomor Handphone Sudah terdaftar"){
                   this.infoError=response.data.message
               }
               else{
                  window.location.href="https://wa.me/62"+this.HpSales
               }
            })
        },

        WhatsApp(){
            if(this.utm_source==null){
                
                return window.location.href="https://wa.me/628111949478?text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website%20"
            }else{
                return window.location.href="https://wa.me/628111949478?text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website%20melalui%20"+this.utm_source+"%20"+this.utm_medium
                // return window.location.href="https://wa.me/628111949478?text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website%20melalui%20"+this.utm_source+"%20"+this.utm_medium+"%20"+this.utm_campaign
            }
         }
    }
}
</script>
