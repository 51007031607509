<template>
  <div  style="background:url(../assets/img/bg.png);">
    
    <nav class="navbar navbar-light" id="nav">
        <div class="container mt-0 d-flex justify-content-between align-items-start">
          <a class="navbar-brand" href="#">
              <img src="./assets/img/Lofo Allure Mansion Square-01 1.png" class="img-fluid logo" alt="Responsive image" >
          </a>
          <a onclick="return gtag_report_conversion(&#39;https://api.whatsapp.com/send?phone=628111949478&amp;text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website&#39;);" href="https://api.whatsapp.com/send?phone=628111949478&amp;text=Halo,%20Saya%20Tertarik%20Allure%20Mansion%20dari%20Website" class="btn" style="margin-top: 30px;"><h4 class="m-0 p-0"><i class="bi bi-whatsapp"></i> <strong>0811-1949-478</strong></h4></a>
        </div>
      </nav>
    
    <router-view> </router-view>
    <WhatsApp />
 
</div>
</template>

<script>
import WhatsApp from './components/WhatsApp.vue';
export default {
    components:{
        WhatsApp
    }

}

</script>

<style>
nav a, h4 {
    color: #fff;
    letter-spacing: 1px;
}

       
        .logo{
            width: 70%;
            height: 80%;
        }
        @media only screen and (max-width: 1000px) {
            .logo{
                width: 50%;
                height: 40%;
            }
        }
        @media only screen and (max-width: 600px) {
            .logo{
                width: 30%;
                height: 40%;
            }
        }

        .image-sales{
            height: 200px;
            width: 200px;
            overflow: hidden;
            background-color: #5F4838;
            border-radius: 50%;
        }

        /* @media only screen and (max-width: 1000px) {
            .image-sales{
            height: 100%;
            width: 70%;
            overflow: hidden;
            border-radius: 50%;
          }
        } 
      */
        .fa-instagram {
        color: #70625A;
        font-size: 380%;
        }
        .fa-facebook {
        color: #70625A;
         font-size: 350%;
        }
        #nav{
            background-color: #806858;
            height: 90px;
            margin-top: -10px;
            padding-top: 0px;
        }
        @media only screen and (max-width: 1000px) {
        #nav{
            background-color: #806858;
            height: 80px;
            margin-top: -10px;
            padding-top: 0px;
        }
        }

        @media only screen and (max-width: 600px) {
        #nav{
            background-color: #806858;
            height: 60px;
            margin-top: -10px;
            padding-top: 0px;
        }
        }

        #sisi{
            text-align: start;
        }
        #t{
            font-family: 'Montserrat';
        }
        h3 {
            /* text-align: center; */
            font-size: 27px;
            font-weight: bold;
            color: #70625A;
            font-family: 'Montserrat';
        }
        h2 {
            text-align: center;
            font-size: 27px;
            font-weight: bold;
            color: white;
            font-family: 'Montserrat';
        }
        #tx{
            font-family: 'Montserrat';
            color: #5F4838;
        }
        h4{
            font-weight: bold;
            font-size: 20px;
            font-family: 'Montserrat';
            
        }
        p{
            font-family: 'Montserrat';
            font-size: 19px;
        }
        span{
            font-weight: bold;
            font-size: 13px;
            font-family: 'Montserrat';
            color: white;
            text-align: center;
        }
</style>
